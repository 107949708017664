<template>
  <div>
    <BoxWithTitle
      title="Mentions légales et politique de protection des données"
    >
      <template #body>
        <h2 class="font-bold text-xl text-promy-green-350">
          GUGP – Conditions et Politiques
        </h2>
        <h3 class="mt-2 font-bold text-promy-green-350">Mentions légales</h3>
        <div class="mt-5">
          <h2 class="font-semibold text-xl">1. INFORMATIONS ÉDITEUR</h2>
          <div class="mt-5 leading-[1.5] space-y-1">
            <p>
              Ce site Internet est la propriété de
              <span class="font-semibold">Promy</span> Siret
              <span class="font-semibold">84783169000015</span>
            </p>
            <p>
              RCS <span class="font-semibold">Lyon B</span> numéro de TVA
              intracommunautaire
              <span class="font-semibold">FR76847831690</span>
            </p>
            <p>
              dont le Siège social est :
              <span class="font-semibold">13 rue Emile Zola, 69002 Lyon</span>
            </p>
            <p>Tel. <span class="font-semibold">+33 4 26 00 89 70</span></p>
            <p>
              Adresse email :
              <span class="font-semibold">contact@promy.fr</span>
            </p>
            <p>
              Le Directeur de la publication du site Internet est
              <span class="font-semibold">Romain SOLENNE</span>
            </p>
            <p>
              Les mises à jour sont assurées par
              <span class="font-semibold">PROMY.FR</span>
            </p>
            <p>
              Pour toute suggestion, information, réaction concernant ce site,
              n’hésitez pas à écrire au webmestre via son adresse mail
              <span class="font-semibold">contact@promy.fr</span>
            </p>
          </div>
        </div>
        <div class="mt-5">
          <h2 class="font-semibold text-xl">2. INFORMATIONS GÉNÉRALES</h2>
          <h3 class="mt-5 font-semibold">Contenu du site</h3>
          <p class="leading-[1.5]">
            Le présent Site ne peut garantir l’exactitude de toutes les
            informations contenues sur le site. Cependant, l’éditeur s’efforcera
            de diffuser des informations exactes et à jour, ainsi que de
            corriger les erreurs qui lui seront signalées.
          </p>
          <h3 class="mt-5 font-semibold">Formulaires</h3>
          <div class="leading-[1.5] space-y-1">
            <p>
              Les informations recueillies par le biais des formulaires ne
              seront pas divulguées à des personnes autres que celles composants
              notre établissement.
            </p>
            <p>
              Certaines des informations de ces formulaires sont facultatives,
              d’autres obligatoires et clairement indiquées comme telles afin
              que vos réponses soient suffisantes pour nous permettre de traiter
              l’information.
            </p>
            <p>
              Votre adresse électronique (qui a fait l’objet d’une inscription
              volontaire) servira uniquement à acheminer notre réponse à votre
              question. Elle ne sera en aucun cas communiquée à des tiers.
            </p>
            <p>
              En application de l’article 27 de la loi Informatique et libertés
              en date du 6 février 1978, modifié par la loi n°2004-801 du 6 août
              2004 et par la loi n°2016-1321 du 7 octobre 2016, vous disposez
              d’un droit de modification et de suppression des données qui vous
              concernent. Pour cela, vous êtes libre, à tout moment, de nous le
              signaler à l’adresse :
              <span class="font-semibold">contact@promy.fr</span>
            </p>
          </div>
        </div>
        <div class="mt-5">
          <h2 class="font-semibold text-xl">3. INFORMATIONS TECHNIQUES</h2>
          <div class="mt-5 leading-[1.5] space-y-1">
            <p>Création & hébergement du site</p>
            <p>PROMY</p>
            <p>13 rue Emile Zola – 69002 LYON</p>
            <p>Tel. +33 4 26 00 89 70</p>
          </div>
          <h3 class="mt-5 font-semibold">
            Affichage et consultation des pages du site
          </h3>
          <p class="leading-[1.5]">
            L’affichage des pages du site est optimisé pour une résolution
            d’écran de 1024 x 768, dans une palette de 65536 couleurs. Nous vous
            recommandons la consultation de ces pages avec les principaux
            navigateurs Internet suivants : Microsoft Internet Explorer version
            10, Mozilla Firefox13, Safari MAC/PC, Google Chrome 20.
          </p>
          <h3 class="mt-5 font-semibold">Impression des pages du site</h3>
          <p class="leading-[1.5]">
            Toutes les pages du site sont disponibles dans un format compatible
            avec leur impression. Le texte imprimé mentionne l’emplacement du
            document sur le site.
          </p>
          <h3 class="mt-5 font-semibold">Plugs-in</h3>
          <p class="leading-[1.5]">
            Certains documents sont proposés au format PDF. Pour pouvoir les
            ouvrir, les consulter et les imprimer, vous devez utiliser le module
            Acrobat Reader de la société Adobe. Vous pouvez le télécharger en
            <a
              class="font-semibold !underline text-promy-green-350"
              href="https://get.adobe.com/fr/reader/"
              >cliquant ici</a
            >.
          </p>
        </div>
        <div class="mt-5">
          <h2 class="font-semibold text-xl">4. INFORMATIONS JURIDIQUES</h2>
          <h3 class="mt-5 font-semibold">
            Copyright – propriété intellectuelle
          </h3>
          <div class="leading-[1.5] space-y-1">
            <p>
              Les éléments figurant sur le présent site sont, et demeureront, la
              propriété exclusive du Site. Toute reproduction totale ou
              partielle, modification ou utilisation de ces marques,
              illustrations, photos, images et logotypes, base de données, pour
              quelque motif et sur quelque support que ce soit, sans accord
              exprès et préalable du Site, est strictement interdite. Il en est
              de même de toute combinaison ou conjonction avec toute autre
              marque, symbole, logotype et plus généralement tout signe
              distinctif destiné à former un logo composite. Il en est de même
              pour tout droit d’auteur, dessin, modèle et brevet qui sont la
              propriété du Site.
            </p>
            <p>
              Dans le cadre d’un usage autre que strictement privé, il est
              interdit de résumer, modifier, altérer le contenu textuel et les
              données cartographiques du site Internet sans autorisation
              préalable de l’éditeur. Les demandes doivent être adressées à
              <span class="font-semibold">contact@promy.fr</span>.
            </p>
            <p>
              Pour un usage privé, la reproduction partielle sur support papier
              des données textuelles contenues sur le site est libre. Elle peut
              être réalisée sans autorisation de l’éditeur du site. Cette
              reproduction ne peut cependant être présentée comme une version
              officielle.
            </p>
            <p>
              L’autorisation de reproduire est accordée à l’utilisateur sans
              paiement de droits, sous réserve que le demandeur s´engage à citer
              le nom du propriétaire du site Internet et l´adresse du site
              Internet (avec un lien hypertexte) et sans modification.
            </p>
          </div>
          <h3 class="mt-5 font-semibold">
            Information quant aux liens hypertextes
          </h3>
          <div class="leading-[1.5] space-y-1">
            <p>
              Il est possible de créer un lien vers le site sans autorisation
              expresse de l’éditeur, à la seule condition que ce lien ouvre une
              nouvelle fenêtre du navigateur Internet. Toutefois, l’éditeur se
              réserve le droit de demander la suppression d’un lien qu’il estime
              non conforme à sa politique éditoriale.
            </p>
            <p>
              En outre, la présence de liens hypertextes vers le site ne
              signifie en aucune manière que le site et son éditeur adhèrent à
              leur contenu ou acceptent une responsabilité quelconque pour le
              contenu ou l’utilisation de cet autre site Internet.
            </p>
          </div>
          <h3 class="mt-5 font-semibold">Crédit photographique</h3>
          <p class="leading-[1.5]">
            Les photographies présentes sur ce site ne peuvent faire l’objet
            d’une quelconque reproduction.
          </p>
          <h3 class="mt-5 font-semibold">Cookies</h3>
          <div class="leading-[1.5] space-y-1">
            <p>
              Un cookie est un petit fichier texte, au format alphanumérique,
              déposé sur le disque dur de l’internaute par le serveur du site
              visité.
            </p>
            <p>
              Ces cookies ne comportent aucune information à caractères privé ou
              qui puisse permettre d’identifier le Visiteur. Les cookies
              permettent de reconnaitre un visiteur lorsqu’il revient sur un
              site web ; cependant, sur un plan technique les cookies
              permettent, en fait, de reconnaitre une machine et non réellement
              un utilisateur. Les cookies permettent au Site d’améliorer les
              Services proposés, de reconnaître le Visiteur et de faciliter la
              gestion du Site. Les cookies peuvent également être utilisés à des
              fins statistiques (nombre de visites par jour, par heure …, pages
              consultées, …) Le Visiteur est informé du fait qu’il peut
              accepter, refuser ou paramétrer la mise en place de cookies sur
              son disque dur en suivant la procédure indiquée sur son
              navigateur. Le fait de refuser ou limiter les cookies peut
              perturber l’utilisation du Site Internet.
            </p>
          </div>
        </div>
        <div class="mt-5">
          <h2 class="font-semibold text-xl">
            5. MODIFICATION DES MENTIONS LÉGALES
          </h2>
          <p class="mt-5 leading-[1.5]">
            Les Mentions Légales peuvent être modifiées à tout moment. Ces
            modifications sont publiées par leur mise en ligne et sont réputées
            acceptées sans réserve lorsque vous accédez au Site postérieurement
            à leur mise en ligne. Nous vous recommandons de consulter
            régulièrement la présente page.
          </p>
        </div>
        <div class="mt-5">
          <h2 class="font-semibold text-xl">6. DROIT APPLICABLE</h2>
          <p class="mt-5 leading-[1.5]">
            Le droit applicable au présent Site et à ses mentions légales est le
            droit français.
          </p>
        </div>

        <h2 class="mt-8 font-bold text-xl text-promy-green-350">
          Politique de protection des données personnelles
        </h2>
        <h3 class="mt-4 font-bold text-promy-green-350">
          Charte de protection des données personnelles et de gestion des
          cookies
        </h3>

        <div class="mt-5 leading-[1.5] space-y-1">
          <p>
            La présente charte de protection des données personnelles et de
            gestion des cookies détaille la politique de la Société PROMY en
            matière de protection de la vie privée, de collecte de données
            personnelles et de cookies.
          </p>
          <p>
            Elle s’applique aux données collectées sur le Site Internet
            <a
              class="font-semibold !underline text-promy-green-350"
              href="https://gugp.plateforme.promy.fr"
              >https://gugp.plateforme.promy.fr</a
            >
            .
          </p>
          <p>
            Afin de faciliter l’accès à cette charte, nous la rendons disponible
            dans notre menu ainsi qu’à tout autre endroit où des informations
            personnelles vous sont demandées.
          </p>
          <p>La présente charte a pour objet de vous informer :<br /></p>
          <ul class="list-disc list-inside ml-4 text-[#837e8a]">
            <li>
              sur les moyens que nous mettons en œuvre pour collecter vos
              données à caractère personnel, dans le respect le plus strict de
              vos droits,
            </li>
            <li>
              sur les données susceptibles d’être recueillies sur notre Site,
            </li>
            <li>
              sur l’usage qui peut être fait des données collectées et les
              droits que vous avez sur ces données.
            </li>
          </ul>
          <p class="!mt-2">
            La société PROMY précise par la présente charte et/ou à chaque fois
            qu’il vous est demandé des données à caractère personnel les
            finalités du traitement ainsi que les moyens de toute opération
            (collecte, enregistrement, modification…).
          </p>
          <p>La société PROMY s’engage à :</p>
          <ul class="list-disc list-inside ml-4 text-[#837e8a]">
            <li>
              veiller au respect des principes sur la protection des données
              personnelles,
            </li>
            <li>
              informer les personnes au sujet de l’existence de leurs droits
              d’accès, de rectification et d’opposition,
            </li>
            <li>
              procéder à l’accomplissement des formalités légales et/ou
              réglementaires.
            </li>
          </ul>
          <p class="!mt-2">
            Nous vous indiquons que nous nous conformons, dans la collecte et la
            gestion de vos données à caractère personnel, à la loi n° 78-17 du 6
            janvier 1978 relative à l’informatique, aux fichiers et aux
            libertés, modifiée et dans sa version en vigueur, ainsi qu’au
            règlement général sur la protection des données, adopté le 27 avril
            2016 par le Parlement européen, et aux lois et règlements en
            découlant.
          </p>
        </div>

        <div class="mt-5">
          <h2 class="font-semibold text-xl">1. PROTECTION DES DONNEES</h2>
          <h3 class="mt-3 ml-4 text-[#837e8a] italic">
            1. Définition et nature des données à caractère personnel
          </h3>
          <p class="mt-3 leading-[1.5]">
            Lors de votre utilisation de notre Site Internet, et par
            l’intermédiaire de celui-ci, nous pouvons être amenés à collecter
            des données à caractère personnel, lesquels nous sont communiquées
            dans le cadre suivant :
          </p>
          <ul class="list-disc list-inside mt-2 ml-4 text-[#837e8a]">
            <li>Demande de contact :</li>
            <ul class="ml-8 mt-1">
              <li>○ IP, Nom, prénom : pour établir votre identité,</li>
              <li>
                ○ Adresse email, téléphone : pour vous contacter dans le cadre
                de votre demande de contact
              </li>
            </ul>
            <li class="mt-4">Demande d’étude projet :</li>
            <ul class="ml-8 mt-1">
              <li>○ IP, Nom, prénom : pour établir votre identité,</li>
              <li>
                ○ Adresse email, téléphone : pour vous contacter dans le cadre
                de votre demande d’étude de projet
              </li>
              <li>
                ○ Entreprise, poste (facultatif) : pour répondre à votre demande
                en fonction de l’ activité de votre entreprise ou de votre
                service
              </li>
            </ul>
            <li class="mt-4">Formulaire de demande de recrutement :</li>
            <ul class="ml-8 mt-1">
              <li>○ IP, Nom, prénom : pour établir votre identité,</li>
              <li>
                ○ Adresse email, téléphone : pour vous contacter dans le cadre
                de votre demande de recrutement
              </li>
              <li>
                ○ CV, motivation : pour étudier la correspondance entre votre
                profil et le poste
              </li>
              <li>
                ○ Portfolio, Linkedin (facultatif) : pour compléter vos
                arguments concernant votre correspondance avec le profil du
                poste
              </li>
            </ul>
            <li class="mt-4">Formulaire de demande de stage</li>
            <ul class="ml-8 mt-1">
              <li>○ IP, Nom, prénom : pour établir votre identité,</li>
              <li>
                ○ Adresse email, téléphone : pour vous contacter dans le cadre
                de votre demande de stage
              </li>
              <li>
                ○ CV, motivation : pour étudier la correspondance entre votre
                profil et le stage
              </li>
              <li>
                ○ Portfolio, Linkedin (facultatif) : pour compléter vos
                arguments concernant votre correspondance avec le profil du
                stage
              </li>
            </ul>
            <li class="mt-4">Inscription à notre newsletter</li>
            <ul class="ml-8 mt-1">
              <li>○ IP : pour établir votre identité</li>
              <li>○ Adresse email : pour vous envoyer la newsletter</li>
            </ul>
          </ul>
          <p class="mt-2 leading-[1.5]">
            Par « données à caractère personnel », il y a lieu d’entendre toutes
            les données qui permettent de vous identifier directement ou
            indirectement (par regroupement d’informations) telles que noms,
            prénoms, pseudonyme, date de naissance, photographie, adresses
            postales et de courrier électronique, numéros de téléphone,
            profession, cursus de formation, diplômes, déroulé de carrière et
            expérience professionnelle, données relatives à vos transactions sur
            le site, détails de vos achats et abonnements, relevé d’identité
            bancaire…
          </p>

          <h3 class="mt-3 ml-4 text-[#837e8a] italic">
            2. Droits d’accès, de rectification, de suppression et d’opposition
            au traitement
          </h3>
          <p class="mt-3 leading-[1.5]">
            Conformément à la loi n°78-17 du 6 janvier 1978 « informatique et
            libertés » ainsi qu’aux dispositions du Règlement Général sur la
            Protection des Données, adopté le 27 avril 2016 par le Parlement
            européen, vous disposez d’un droit d’accès, de rectification, de
            suppression et d’opposition au traitement des informations
            personnelles vous concernant. Vous avez également la possibilité de
            vous opposer au traitement à des fins de prospection, notamment
            commerciale.
          </p>
          <p class="mt-2 leading-[1.5]">
            Vous pouvez exercer votre droit d’accès aux données vous concernant
            et les faire rectifier en contactant :
          </p>
          <p class="mt-2">PROMY</p>
          <p class="mt-1">13 rue Emile Zola</p>
          <p class="mt-1">69002 LYON</p>
          <p class="mt-2">ou</p>
          <p class="mt-2">contact@promy.fr</p>
          <p class="mt-2 leading-[1.5]">
            Pour ce faire, nous vous remercions d’adresser un courrier postal ou
            un courrier électronique, signé de l’auteur de la demande et être
            accompagné d’un justificatif d’identité (carte nationale d’identité
            ou passeport) portant signature de la personne qui exerce son droit.
          </p>

          <h3 class="mt-3 ml-4 text-[#837e8a] italic">
            3. Gestion des données personnelles par Promy
          </h3>
          <h4 class="mt-3">3.1 Moyens de collecte des données</h4>
          <p class="mt-2 leading-[1.5]">
            Lors de votre navigation sur le site Internet
            <a
              class="font-semibold !underline text-promy-green-350"
              href="https://gugp.plateforme.promy.fr"
              >https://gugp.plateforme.promy.fr</a
            >
            et afin de pouvoir vous offrir les services proposés sur celui-ci,
            nous pouvons être amenés à collecter des données personnelles vous
            concernant. Avant chaque collecte de données à caractère personnel,
            nous recueillons votre consentement par le biais d’un formulaire à
            compléter et d’une case à cocher après avoir pris connaissance de la
            présente Charte de protection des données.
          </p>
          <h4 class="mt-3">3.2 Finalité de la collecte des données</h4>
          <p class="mt-2 leading-[1.5]">
            Vos données à caractère personnel sont collectées pour répondre à
            une ou plusieurs finalités suivantes :
          </p>
          <ul class="list-disc list-inside ml-4 mt-2 space-y-1 text-[#837e8a]">
            <li>Vous envoyer les newsletters ;</li>
            <li>
              Gérer vos abonnements et vos désabonnements à la newsletter ;
            </li>
            <li>
              Élaborer des statistiques dans le but notamment d’améliorer notre
              site ;
            </li>
            <li>
              Assurer le suivi de la relation client notamment dans la gestion
              de vos réclamations et vos demandes de projet ;
            </li>
            <li>
              Gérer vos demandes de droit d’accès, de rectification,
              d’opposition de vos données ;
            </li>
            <li>
              Conserver vos données dans des documents légaux en conformité avec
              les normes comptables, pour la durée légale nécessaire, en vue de
              répondre aux sollicitations des administrations fiscales et
              organismes sociaux si besoin ;
            </li>
            <li>
              Pour les candidatures déposées en ligne, afin d’étudier votre
              demande et pouvoir vous contacter si un poste se libère ou est
              créé dans notre entreprise.
            </li>
          </ul>
          <p class="mt-2 leading-[1.5]">
            Nous nous engageons à collecter vos données en nous limitant aux
            données strictement nécessaires à la finalité du traitement
            envisagé, afin de pouvoir traiter vos demandes et vous offrir les
            services proposés sur notre site Internet.
          </p>
          <h4 class="mt-3">3.3 Conservation des données</h4>
          <p class="mt-2 leading-[1.5]">
            Les données à caractère personnel que nous sommes amenés à collecter
            sont conservées pendant une durée qui n’excède pas la durée
            nécessaire aux finalités pour lesquelles elles ont été collectées et
            au maximum pour une durée de :
          </p>
          <ul class="list-disc list-inside ml-4 mt-2 space-y-1 text-[#837e8a]">
            <li>
              3 ans à compter de la fin de la relation commerciale pour des
              partenaires ou clients, et 3 ans à compter de la collecte de
              données ou du dernier contact pour les prospects, et ce, dans l’un
              et l’autre cas, uniquement à des fins de prospection commerciale
            </li>
            <li class="mt-1">
              3 ans à compter de la collecte de données ou du dernier contact
              pour les demandes de candidatures ou de stage
            </li>
          </ul>
          <p class="mt-2 leading-[1.5]">
            A l’issue, dès lors que les données collectées ne sont plus
            nécessaires à la finalité pour laquelle elles avaient été collectées
            :
          </p>
          <ul class="list-disc list-inside ml-4 mt-2 space-y-1 text-[#837e8a]">
            <li>
              nous nous engageons à reprendre contact avec vous afin de savoir
              si oui ou non vous souhaitez continuer à recevoir des
              sollicitations commerciales ;
            </li>
            <li class="mt-1">
              à défaut, les données seront archivées et anonymisées ou
              détruites.
            </li>
          </ul>
          <p class="mt-2 leading-[1.5]">
            À tout moment, vous pouvez vous opposer au traitement des
            informations personnelles vous concernant puisque vous disposez d’un
            droit d’accès, de rectification, de suppression et d’opposition
            ainsi qu’il a été exposé ci-avant.
          </p>
          <h4 class="mt-3">3.4 Destinataire des données</h4>
          <p class="mt-2 leading-[1.5]">
            Les données à caractère personnelle collectées, vous concernant,
            sont destinées à la société DE BUSSAC MULTIMEDIA.
          </p>
          <p class="mt-2 leading-[1.5]">
            Afin de pouvoir réaliser les traitements ci-dessus énumérés, nous
            sommes amenés à partager vos données, en donnant accès aux
            personnels autorisés et dûment habilités à cet effet, à savoir nos
            services internes.
          </p>
          <p class="mt-2 leading-[1.5]">
            Les données que nous sommes amenés à collecter vous concernant n’ont
            pas vocation à être transférées hors de l’Union Européenne.
            Toutefois, si tel devait être le cas, nous vous garantissons, en cas
            de transfert de vos données à l’étranger et particulièrement hors de
            l’Union européenne, mettre en œuvre toutes les mesures appropriées
            pour garantir un niveau de protection suffisant de vos données,
            telles que :
          </p>
          <ul class="list-disc list-inside ml-4 mt-2 space-y-1 text-[#837e8a]">
            <li>
              S’orienter vers des pays dits « sûrs », c’est-à-dire offrant une
              protection importante de vos données ;
            </li>
            <li class="mt-1">
              Demander les autorisations nécessaires auprès de l’Autorité de
              contrôle avant toute collecte de données si celle-ci l’exige selon
              les pays ;
            </li>
            <li class="mt-1">
              Obtenir des garanties de sécurisation et de confidentialité de la
              part des sous- traitants, par exemple, en imposant des clauses
              contractuelles strictes, en se réservant la possibilité de les
              contrôler régulièrement et en menant des audits.
            </li>
            <li class="mt-1">
              Sécurité et confidentialité des données à caractère personnel
              collectées
            </li>
          </ul>
          <p class="mt-2 leading-[1.5]">
            La sécurité et la confidentialité des données personnelles que vous
            nous confiez, est une priorité pour nous.
          </p>
          <p class="mt-2 leading-[1.5]">
            En effet, nous veillons à prendre toutes les mesures techniques et
            organisationnelles utiles au regard de la nature des données
            personnelles que vous nous communiquez et des risques présentés par
            leur traitement.
          </p>
          <p class="mt-2 leading-[1.5]">
            Ceci afin de préserver la sécurité de vos données personnelles et,
            notamment, empêcher qu’elles soient déformées, détruites,
            endommagées, ou que des tiers non autorisés y aient accès.
          </p>
          <p class="mt-2 leading-[1.5]">
            À cette fin, nous avons mis en place des mesures techniques telles
            que :
          </p>
          <ul class="ml-4 mt-2 space-y-1">
            <li>– la sécurisation des accès aux données du site</li>
            <li class="mt-1">
              – nous nous assurons que le site et les technologies qui le
              compose soient toujours à jour des dernières mises à jour de
              sécurité
            </li>
            <li class="mt-1">
              – nous contrôlons de manière rigoureuse l’accès aux données
            </li>
            <li class="mt-1">
              – l’utilisation de modules et de différentes techniques afin de
              sécuriser le site en lui-même
            </li>
          </ul>
          <p class="mt-2 leading-[1.5]">
            et des mesures organisationnelles telles :
          </p>
          <ul class="ml-4 mt-2 space-y-1">
            <li>
              – qu’un accès restreint au serveur, limité aux équipes compétentes
            </li>
            <li class="mt-1">
              – une gestion rigoureuse des accès au code source du site, ainsi
              qu’à la base de données
            </li>
            <li class="mt-1">
              – nous contrôlons de manière rigoureuse l’accès aux données
            </li>
            <li class="mt-1">
              – un contrôle régulier que les points ci-dessous sont correctement
              appliqués
            </li>
          </ul>
          <p class="mt-2 leading-[1.5]">
            La sécurité et la confidentialité des données personnelles reposent
            également sur les bonnes pratiques de chacun. Ainsi, nous vous
            invitons à ne pas communiquer vos identifiant et mot de passe à des
            tiers, à vous déconnecter systématiquement de votre profil et de
            votre compte client (notamment en cas de comptes liés), à fermer la
            fenêtre de votre navigateur à l’issue de votre session,
            particulièrement si vous accédez à Internet depuis un poste
            informatique partagé avec d’autres personnes, et à ne pas
            enregistrer vos identifiant et mot de passe dans votre navigateur.
            Vous éviterez ainsi que d’autres utilisateurs accèdent à vos
            informations personnelles.
          </p>
        </div>
        <div class="mt-5">
          <h2 class="font-semibold text-xl">
            2. POLITIQUE DE GESTION DES COOKIES
          </h2>
          <p class="mt-3 leading-[1.5]">
            Le terme de « cookies » regroupe l’ensemble des traceurs déposés
            lors de la consultation d’un site internet, de la lecture d’un
            courrier électronique, de l’utilisation d’un logiciel ou d’une
            application mobile notamment. Il s’agit d’un fichier texte que le
            site que vous visitez enregistre sur votre disque dur ou dans la
            mémoire de votre navigateur. Un cookie permet à votre ordinateur de
            stocker différentes données techniques permettant le contrôle
            général de l’accès du public à nos sites (nombre de visites,
            fréquence d’exposition à une bannière publicitaire, connexion à
            d’autres sites …) ou la personnalisation des pages affichées en vue
            de vos prochaines interactions avec ce site.
          </p>
          <p class="mt-2 leading-[1.5]">
            Lorsque vous naviguez sur notre Site, des informations relatives à
            votre navigation sont susceptibles d’être enregistrées dans des
            fichiers « Cookies » installés sur votre ordinateur ou tablette ou
            smartphone.
          </p>
          <p class="mt-2 leading-[1.5]">
            Les cookies sont importants pour le bon fonctionnement d’un site.
            Les cookies sont utilisés pour collecter les statistiques en vue
            d’optimiser les fonctionnalités du site et vous offrir des
            possibilités de partage votre expérience sur les réseaux sociaux.
          </p>
          <p class="mt-2 leading-[1.5]">
            Ainsi, lorsque vous visitez notre Site pour la première fois, et que
            des cookies peuvent être installés sur votre terminal, nous vous
            informons du fait que des cookies sont utilisés par le Site, par une
            fenêtre s’ouvrant sur la page visitée.
          </p>
          <p class="mt-2 leading-[1.5]">
            Aux termes de cette information, vous pouvez accéder à la présente
            Charte et vous pouvez accepter ou refuser les cookies.
          </p>
          <p class="mt-2 leading-[1.5]">
            Nous attirons votre attention sur le fait qu’en continuant votre
            navigation sur le Site lors de l’affichage de cette fenêtre, vous
            consentez à l’utilisation des cookies sur notre Site. Vous pouvez
            néanmoins à tout moment modifier les paramètres relatifs aux cookies
            ainsi qu’il est exposé ci-après.
          </p>

          <h3 class="mt-3 ml-4 text-[#837e8a] italic">1. Types de cookies</h3>
          <p class="mt-2 leading-[1.5]">
            Plusieurs types de cookies existent et vous permettent d’avoir
            accès, dès lors que vous les acceptez, à un certain nombre de
            fonctionnalités.
          </p>
          <ul class="list-disc list-inside mt-2 ml-4 text-[#837e8a]">
            <li>Les cookies émis par la société PROMY sur son Site :</li>
            <p class="mt-2 leading-[1.5] text-promy-purply-900">
              Il s’agit des cookies déposés par la société PROMY sur votre
              ordinateur, tablette ou smartphone notamment pour les besoins de
              la navigation sur notre Site.
            </p>
            <p class="mt-2 leading-[1.5] text-promy-purply-900">
              Lors de votre navigation sur notre Site, votre ordinateur dialogue
              avec un serveur qui lui fournit toutes les ressources demandées en
              enregistrant automatiquement chaque opération dans un fichier
              spécifique dans lequel votre ordinateur est identifié par son
              adresse IP. Votre navigateur nous transmet ainsi automatiquement
              certaines données standard : outre votre adresse IP, il s’agit
              notamment du type de navigateur que vous utilisez, de ses
              fonctionnalités, de votre système d’exploitation, de
              l’identification de sites tiers (Facebook, Twitter…) à partir
              desquels vous vous êtes éventuellement connectés ainsi que des
              dates et heures d’accès à nos sites.
            </p>
            <p class="mt-2 leading-[1.5] text-promy-purply-900">
              Ces données et en particulier votre adresse IP ne nous permettent
              pas de vous identifier nominativement.
            </p>
            <p class="mt-2 leading-[1.5] text-promy-purply-900">
              Les cookies que nous émettons nous permettent :
            </p>
            <ul class="ml-8 mt-1">
              <li>
                ○ de surveiller l’activité générale de notre Site, de repérer
                les erreurs et de veiller à ce que notre Site bénéficie des
                ressources matérielles adaptées ;
              </li>
              <li>
                ○ d’établir des statistiques et volumes de fréquentation et
                d’utilisation des divers éléments composant notre Site ;
              </li>
              <li>
                ○ de sauvegarder vos choix en matière de consentement des
                cookies
              </li>
            </ul>
            <p class="mt-2 leading-[1.5] text-promy-purply-900">
              Vous êtes libre d’accepter ou de refuser ces cookies. Il vous
              suffit de modifier certains paramètres de votre navigateur de
              façon à refuser les cookies ou à être averti en cas
              d’enregistrement d’un cookie sur votre ordinateur. Il vous est
              également possible d’effacer les cookies stockés sur votre
              ordinateur afin de supprimer définitivement les informations
              qu’ils contiennent.
            </p>

            <li>Les cookies émis sur notre Site par des tiers:</li>
            <p class="mt-2 leading-[1.5] text-promy-purply-900">
              L’émission et l’utilisation de cookies par des tiers sont soumises
              aux politiques de protection de la vie privée de ces tiers. Nous
              vous informons de l’objet des cookies dont nous avons connaissance
              et des moyens dont vous disposez pour effectuer des choix à
              l’égard de ces cookies.
            </p>

            <li>
              Des boutons de partage (twitter, facebook, google +, pinterest)
            </li>
            <p class="mt-2 leading-[1.5] text-promy-purply-900">
              Vous pouvez à tout moment vous informer et paramétrer vos cookies
              pour les accepter ou les refuser sur Gestion de vos préférences
              sur les cookies. Vous pourrez indiquer votre préférence soit
              globalement pour le site, soit service par service.
            </p>

            <li>Les cookies de mesure d’audience:</li>
            <p class="mt-2 leading-[1.5] text-promy-purply-900">
              Nous utilisons Google Analytics pour collecter des informations
              sur l’utilisation que vous avez de notre Site, afin de :
            </p>
            <ul class="ml-8 mt-1">
              <li>
                ○ nous aider à mesurer et étudier l’efficacité du contenu de
                notre Site,
              </li>
              <li>
                ○ suivre les données analytiques sur nos sites et l’utilisation
                faite de nos sites par les Utilisateurs,
              </li>
              <li>○ améliorer les services de nos sites.</li>
            </ul>
            <p class="mt-2 leading-[1.5] text-promy-purply-900">
              Google Analytics collecte les informations sur les pages que vous
              visitez et le temps que vous y passer. Nous ne collectons pas vos
              informations personnelles.
            </p>
            <p class="mt-2 leading-[1.5] text-promy-purply-900">
              Nous n’autorisons pas le partage ou l’utilisation de ces données.
            </p>
            <p class="mt-2 leading-[1.5] text-promy-purply-900">
              Vous pouvez définir dans les paramètres de ne pas envoyer
              d’information au service Google Analytics. Vous pouvez à tout
              moment vous informer et paramétrer vos cookies pour les accepter
              ou les refuser sur Gestion de vos préférences sur les cookies.
              Vous pourrez indiquer votre préférence soit globalement pour le
              site, soit service par service.
            </p>
          </ul>

          <h3 class="mt-3 ml-4 text-[#837e8a] italic">
            2. Durée de vie des cookies
          </h3>
          <p class="mt-2 leading-[1.5]">
            Conformément à la réglementation en vigueur, les cookies collectés
            sur notre Site Internet ont une durée de vie de 13 mois maximum.
          </p>

          <h3 class="mt-3 ml-4 text-[#837e8a] italic">
            3. Gérer les préférences cookies
          </h3>
          <p class="mt-2 leading-[1.5]">
            Vous disposez de différents moyens pour gérer les cookies. Votre
            navigateur peut également être paramétré pour vous signaler les
            cookies qui sont déposés dans votre ordinateur, tablette ou
            smartphone et vous demander de les accepter ou non.
          </p>
          <p class="mt-2 leading-[1.5]">
            Vous avez la possibilité d’accepter ou de refuser les cookies au cas
            par cas ou bien de les refuser systématiquement. Toutefois, nous
            attirons votre attention sur le fait que tout paramétrage que vous
            pouvez entreprendre sera susceptible de modifier votre navigation
            sur Internet et vos conditions d’accès à certains services pourront
            nécessiter l’utilisation des cookies. Le paramétrage des cookies est
            donc susceptible de bloquer votre accès à certaines fonctionnalités
            du site. Vous pouvez faire le choix, à tout moment, de modifier vos
            souhaits en matière de cookies en suivant la procédure ci-après
            mentionnée.
          </p>
          <p class="mt-2 leading-[1.5]">
            La configuration de chaque navigateur est différente. Elle est
            décrite dans le menu d’aide de votre navigateur, qui vous permettra
            de savoir de quelle manière modifier vos souhaits en matière de
            cookies.
          </p>
          <p class="mt-2 leading-[1.5]">
            Vous pouvez désactiver les cookies en suivant les instructions
            suivantes :
          </p>
          <ul class="ml-4 mt-2 space-y-1">
            <li>
              <a
                class="font-semibold !underline text-promy-green-350"
                href="http://windows.microsoft.com/fr-FR/windows-vista/Block-or-allow-cookies"
                >Internet explorer</a
              >
            </li>
            <li class="mt-1">
              <a
                class="font-semibold !underline text-promy-green-350"
                href="http://support.apple.com/kb/ht1677?viewlocale=fr_FR"
                >Safari</a
              >
            </li>
            <li class="mt-1">
              <a
                class="font-semibold !underline text-promy-green-350"
                href="http://support.google.com/chrome/answer/95647?hl=fr"
                >Chrome</a
              >
            </li>
            <li class="mt-1">
              <a
                class="font-semibold !underline text-promy-green-350"
                href="https://support.mozilla.org/fr/kb/activer-desactiver-cookies?redirectlocale=fr&redirectslug=Activer+et+d%C3%A9sactiver+les+cookies"
                >Firefox</a
              >
            </li>
            <li class="mt-1">
              Pour les autres navigateurs : veuillez consulter votre
              documentation ou des fichiers d’aide en ligne
            </li>
          </ul>
        </div>
        <div class="mt-5">
          <h2 class="font-semibold text-xl">3. MISE A JOUR DE LA CHARTE</h2>
          <p class="mt-3 leading-[1.5]">
            Nous nous réservons le droit de modifier la présente Charte, à tout
            moment, en tout ou partie compte tenu des modifications et
            évolutions de nos usages et procédures internes. Ces dernières
            veilleront à être toujours en conformité avec les éventuelles
            modifications des dispositions législatives et réglementaires
            françaises et européennes. Nous vous invitons à consulter
            régulièrement cette Charte, avant toute collecte de vos données,
            pour une meilleure connaissance et compréhension de l’utilisation de
            vos données à caractère personnel. Nous pourrons être amenés à vous
            demander, à intervalle régulier, de valider votre connaissance de la
            présente charte.
          </p>
          <p class="mt-2 leading-[1.5]">
            Date d’actualisation et de dernière mise à jour de la présente
            Charte : 30 novembre 2021
          </p>
        </div>
      </template>
    </BoxWithTitle>
    <RevelationMobileButton />
  </div>
</template>
<script>
import BoxWithTitle from '../Components/BoxWithTitle.vue'
export default {
  components: {
    BoxWithTitle,
  },
}
</script>
